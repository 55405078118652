import { firestore } from './firebase'

import { Settings } from '../contexts/app/appContextTypes'


// TODO: record submission time for user in form submissions list

export const getForm = (lookupId: string): Promise<Settings | void> => {
  console.log('getForm, lookupId:', lookupId)

  return new Promise((resolve, reject) => {
    firestore.collection('lookup')
      .doc(lookupId)
      .get()
      .then((lookupDoc) => {
        if (lookupDoc.exists) {
          const { user, formId } = lookupDoc.data() as {
            user: string
            formId: string
          }
          firestore.collection('users')
            .doc(user)
            .collection('forms')
            .doc(formId)
            .get()
            .then((formDoc) => {
              if (formDoc.exists) {
                const form = formDoc.data() as Settings
                console.log('formDoc', { ...form, formCreatorId: user })
                resolve({ ...form, formCreatorId: user })
              } else {
                console.error('Lookup exists but form not found')
                reject('Lookup exists but form not found')
              }
            })
        } else {
          // TODO: handle form not found errors
          console.error('Lookup not found')
          reject('Lookup not found')
        }
      })
  })
}
