import axios from 'axios'
import { nanoid } from 'nanoid'

export const logFormStart = async ({
  formId,
  respondent,
  storeSubmissionId,
  formCreatorId,
}: {
  formId: string
  respondent?: string
  storeSubmissionId: (submissionId: string) => void
  formCreatorId?: string
}): Promise<void> => {
  return new Promise((resolve, reject) => {


    // create submission id, it will be found both in form response and firestore
    const submissionId = nanoid().slice(-8)

    console.log('\n--- starting form ---')
    console.log('form id', formId)
    console.log('submission id', submissionId)
    console.log('respondent', respondent)
    console.log('formCreatorId', formCreatorId)
    console.log('\n')

    storeSubmissionId(submissionId)


    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/logFormStart`,
        {
          formId,
          submission: submissionId,
          // respondent: usr.displayName || usr.email,
          respondent,
          formCreatorId: formCreatorId || '',
        }
      )
      .then((res) => {
        console.log(res)
        console.log(res.data)
        resolve()
      })
      .catch(() => {
        reject('Failed to register form start in firestore')
      })
  })
}
