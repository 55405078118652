import React from "react"
import Typography from "@material-ui/core/Typography"

const TermsOfService = () => {
    return (
        <div className="container">
            <Typography variant="h5" className="pt-4">
                Timer for Google Forms
            </Typography>
            <Typography variant="h4" className="py-2">
                Terms of service
            </Typography>

            <P title>1. Your relationship with Timer for Google Forms (TfGF)</P>
            <P>
                1.1 Your use of TfGF’s products, software, services and web sites (referred to collectively as the
                “Services” in this document and excluding any services provided to you by TfGF under a separate written
                agreement) is subject to the terms of a legal agreement between you and TfGF. “TfGF” means Timer for
                Google Forms Inc., whose principal place of business is at Crochy Road 20, 1024 Ecublens, Switzerland.
                This document explains how the agreement is made up, and sets out some of the terms of that agreement.
            </P>
            <P>
                1.2 Unless otherwise agreed in writing with TfGF, your agreement with TfGF will always include, at a
                minimum, the terms and conditions set out in this document. These are referred to below as the
                “Universal Terms”.
            </P>
            <P>
                1.3 Your agreement with TfGF will also include the terms of any Legal Notices applicable to the
                Services, in addition to the Universal Terms. All of these are referred to below as the “Additional
                Terms”. Where Additional Terms apply to a Service, these will be accessible for you to read either
                within, or through your use of, that Service.
            </P>

            <P>
                1.4 The Universal Terms, together with the Additional Terms, form a legally binding agreement between
                you and TfGF in relation to your use of the Services. It is important that you take the time to read
                them carefully. Collectively, this legal agreement is referred to below as the “Terms”.
            </P>

            <P>
                1.5 If there is any contradiction between what the Additional Terms say and what the Universal Terms
                say, then the Additional Terms shall take precedence in relation to that Service.
            </P>
            <P title>2. Accepting the Terms</P>

            <P>
                2.1 In order to use the Services, you must firstly agree to the Terms. You may not use the Services if
                you do not accept the Terms.
            </P>

            <P>
                2.2 You can accept the Terms by: (A) clicking to accept or agree to the Terms, where this option is made
                available to you by TfGF in the user interface for any Service; or (B) by actually using the Services.
                In this case, you understand and agree that TfGF will treat your use of the Services as acceptance of
                the Terms from that point onwards.
            </P>

            <P>
                2.3 You may not use the Services and may not accept the Terms if (a) you are not of legal age to form a
                binding contract with TfGF, or (b) you are a person barred from receiving the Services under the laws of
                the United States, Switzerland, or other countries including the country in which you are resident or
                from which you use the Services.
            </P>

            <P>
                2.4 Before you continue, you should print off or save a local copy of the Universal Terms for your
                records.
            </P>
            <P title>3. Language of the Terms</P>
            <P>
                3.1 Where TfGF has provided you with a translation of the English language version of the Terms, then
                you agree that the translation is provided for your convenience only and that the English language
                versions of the Terms will govern your relationship with TfGF.
            </P>
            <P>
                3.2 If there is any contradiction between what the English language version of the Terms says and what a
                translation says, then the English language version shall take precedence.
            </P>
            <P title>4. Provision of the Services by TfGF </P>
            <P>
                4.1 TfGF has subsidiaries and affiliated legal entities around the world (“Subsidiaries and
                Affiliates”). Sometimes, these companies will be providing the Services to you on behalf of TfGF itself.
                You acknowledge and agree that Subsidiaries and Affiliates will be entitled to provide the Services to
                you.
            </P>
            <P>
                4.2 TfGF is constantly innovating in order to provide the best possible experience for its users. You
                acknowledge and agree that the form and nature of the Services which TfGF provides may change from time
                to time without prior notice to you.
            </P>
            <P>
                4.3 As part of this continuing innovation, you acknowledge and agree that TfGF may stop (permanently or
                temporarily) providing the Services (or any features within the Services) to you or to users generally
                at TfGF’s sole discretion, without prior notice to you. You may stop using the Services at any time. You
                do not need to specifically inform TfGF when you stop using the Services.
            </P>
            <P>
                4.4 You acknowledge and agree that if TfGF disables access to your account, you may be prevented from
                accessing the Services, your account details or any files or other content which is contained in your
                account.
            </P>
            <P>
                4.5 You acknowledge and agree that while TfGF may not currently have set a fixed upper limit on the
                number of transmissions you may send or receive through the Services or on the amount of storage space
                used for the provision of any Service, such fixed upper limits may be set by TfGF at any time, at TfGF’s
                discretion.
            </P>
            <P title>5. Use of the Services by you </P>
            <P>
                5.1 In order to access certain Services, you may be required to provide information about yourself (such
                as identification or contact details) as part of the registration process for the Service, or as part of
                your continued use of the Services. You agree that any registration information you give to TfGF will
                always be accurate, correct and up to date.
            </P>
            <P>
                5.2 You agree to use the Services only for purposes that are permitted by (a) the Terms and (b) any
                applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions
                (including any laws regarding the export of data or software to and from the United States, Switzerland,
                or other relevant countries).
            </P>
            <P>
                5.3 You agree not to access (or attempt to access) any of the Services by any means other than through
                the interface that is provided by TfGF, unless you have been specifically allowed to do so in a separate
                agreement with TfGF.
            </P>
            <P>
                5.4 You agree that you will not engage in any activity that interferes with or disrupts the Services (or
                the servers and networks which are connected to the Services).
            </P>
            <P>
                5.5 Unless you have been specifically permitted to do so in a separate agreement with TfGF, you agree
                that you will not reproduce, duplicate, copy, sell, trade or resell the Services for any purpose.
            </P>
            <P>
                5.6 You agree that you are solely responsible for (and that TfGF has no responsibility to you or to any
                third party for) any breach of your obligations under the Terms and for the consequences (including any
                loss or damage which TfGF may suffer) of any such breach.
            </P>
            <P title>6. Your passwords and account security </P>
            <P>
                6.1 You agree and understand that you are responsible for maintaining the confidentiality of passwords
                associated with any account you use to access the Services.
            </P>
            <P>
                6.2 Accordingly, you agree that you will be solely responsible to TfGF for all activities that occur
                under your account.
            </P>
            <P>
                6.3 If you become aware of any unauthorised use of your password or of your account, you agree to notify
                TfGF immediately at contact@form-timer.com .
            </P>
            <P title>7. Privacy and your personal information </P>
            <P>
                7.1 For information about TfGF’s data protection practices, please read TfGF’s privacy policy at
                https://form-timer.com/privacy. This policy explains how TfGF treats your personal information, and
                protects your privacy, when you use the Services.
            </P>
            <P>7.2 You agree to the use of your data in accordance with TfGF’s privacy policies.</P>
            <P title>8. Content in the Services </P>
            <P>
                8.1 You understand that all information (such as data files, written text, computer software, music,
                audio files or other sounds, photographs, videos or other images) which you may have access to as part
                of, or through your use of, the Services are the sole responsibility of the person from which such
                content originated. All such information is referred to below as the “Content”.
            </P>
            <P>
                8.2 You should be aware that Content presented to you as part of the Services, including but not limited
                to advertisements in the Services and sponsored Content within the Services may be protected by
                intellectual property rights which are owned by the sponsors or advertisers who provide that Content to
                TfGF (or by other persons or companies on their behalf). You may not modify, rent, lease, loan, sell,
                distribute or create derivative works based on this Content (either in whole or in part) unless you have
                been specifically told that you may do so by TfGF or by the owners of that Content, in a separate
                agreement.
            </P>
            <P>
                8.3 TfGF reserves the right (but shall have no obligation) to pre-screen, review, flag, filter, modify,
                refuse or remove any or all Content from any Service. For some of the Services, TfGF may provide tools
                to filter out explicit sexual content. In addition, there are commercially available services and
                software to limit access to material that you may find objectionable.
            </P>
            <P>
                8.4 You understand that by using the Services you may be exposed to Content that you may find offensive,
                indecent or objectionable and that, in this respect, you use the Services at your own risk.
            </P>
            <P>
                8.5 You agree that you are solely responsible for (and that TfGF has no responsibility to you or to any
                third party for) any Content that you create, transmit or display while using the Services and for the
                consequences of your actions (including any loss or damage which TfGF may suffer) by doing so.
            </P>
            <P title>9. Proprietary rights </P>
            <P>
                9.1 You acknowledge and agree that TfGF (or TfGF’s licensors) own all legal right, title and interest in
                and to the Services, including any intellectual property rights which subsist in the Services (whether
                those rights happen to be registered or not, and wherever in the world those rights may exist). You
                further acknowledge that the Services may contain information which is designated confidential by TfGF
                and that you shall not disclose such information without TfGF’s prior written consent.
            </P>
            <P>
                9.2 Unless you have agreed otherwise in writing with TfGF, nothing in the Terms gives you a right to use
                any of TfGF’s trade names, trade marks, service marks, logos, domain names, and other distinctive brand
                features.
            </P>
            <P>
                9.3 If you have been given an explicit right to use any of these brand features in a separate written
                agreement with TfGF, then you agree that your use of such features shall be in compliance with that
                agreement, any applicable provisions of the Terms, and TfGF's brand feature use guidelines as updated
                from time to time.
            </P>
            <P>
                9.4 Other than the limited license set forth in Section 11. TfGF acknowledges and agrees that it obtains
                no right, title or interest from you (or your licensors) under these Terms in or to any Content that you
                submit, post, transmit or display on, or through, the Services, including any intellectual property
                rights which subsist in that Content (whether those rights happen to be registered or not, and wherever
                in the world those rights may exist). Unless you have agreed otherwise in writing with TfGF, you agree
                that you are responsible for protecting and enforcing those rights and that TfGF has no obligation to do
                so on your behalf.
            </P>
            <P>
                9.5 You agree that you shall not remove, obscure, or alter any proprietary rights notices (including
                copyright and trademark notices) which may be affixed to or contained within the Services.
            </P>
            <P>
                9.6 Unless you have been expressly authorised to do so in writing by TfGF, you agree that in using the
                Services, you will not use any trade mark, service mark, trade name, logo of any company or organisation
                in a way that is likely or intended to cause confusion about the owner or authorised user of such marks,
                names or logos.
            </P>
            <P title>10. Licence from TfGF </P>
            <P>
                10.1 TfGF gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive licence to use
                the software provided to you by TfGF as part of the Services as provided to you by TfGF (referred to as
                the “Software” below). This licence is for the sole purpose of enabling you to use and enjoy the benefit
                of the Services as provided by TfGF, in the manner permitted by the Terms.
            </P>
            <P>
                10.2 You may not (and you may not permit anyone else to) copy, modify, create a derivative work of,
                reverse engineer, decompile or otherwise attempt to extract the source code of the Software or any part
                thereof, unless this is expressly permitted or required by law, or unless you have been specifically
                told that you may do so by TfGF, in writing.
            </P>
            <P>
                10.3 Unless TfGF has given you specific written permission to do so, you may not assign (or grant a
                sub-licence of) your rights to use the Software, grant a security interest in or over your rights to use
                the Software, or otherwise transfer any part of your rights to use the Software.
            </P>
            <P title>11. Content licence from you </P>
            <P>
                11.1 You retain copyright and any other rights you already hold in Content which you submit, post or
                display on or through, the Services. By submitting, posting or displaying the content you give TfGF a
                perpetual, irrevocable, worldwide, royalty-free, and non-exclusive licence to reproduce, adapt, modify,
                translate, publish, publicly perform, publicly display and distribute any Content which you submit, post
                or display on or through, the Services. This licence is for the sole purpose of enabling TfGF to
                display, distribute and promote the Services and may be revoked for certain Services as defined in the
                Additional Terms of those Services.
            </P>
            <P>
                11.2 You agree that this licence includes a right for TfGF to make such Content available to other
                companies, organisations or individuals with whom TfGF has relationships for the provision of syndicated
                services, and to use such Content in connection with the provision of those services.
            </P>
            <P>
                11.3 You understand that TfGF, in performing the required technical steps to provide the Services to our
                users, may (a) transmit or distribute your Content over various public networks and in various media;
                and (b) make such changes to your Content as are necessary to conform and adapt that Content to the
                technical requirements of connecting networks, devices, services or media. You agree that this licence
                shall permit TfGF to take these actions.
            </P>
            <P>
                11.4 You confirm and warrant to TfGF that you have all the rights, power and authority necessary to
                grant the above licence.
            </P>
            <P title>12. Software updates </P>
            <P>
                12.1 The Software which you use may automatically download and install updates from time to time from
                TfGF. These updates are designed to improve, enhance and further develop the Services and may take the
                form of bug fixes, enhanced functions, new software modules and completely new versions. You agree to
                receive such updates (and permit TfGF to deliver these to you) as part of your use of the Services.
            </P>
            <P title>13. Ending your relationship with TfGF </P>
            <P>13.1 The Terms will continue to apply until terminated by either you or TfGF as set out below.</P>
            <P>
                13.2 If you want to terminate your legal agreement with TfGF, you may do so by (a) notifying TfGF at any
                time and (b) closing your accounts for all of the Services which you use, where TfGF has made this
                option available to you. Your notice should be sent, in writing, to TfGF’s address which is set out at
                the beginning of these Terms.
            </P>
            <P>
                13.3 TfGF may at any time, terminate its legal agreement with you if: (A) you have breached any
                provision of the Terms (or have acted in manner which clearly shows that you do not intend to, or are
                unable to comply with the provisions of the Terms); or (B) TfGF is required to do so by law (for
                example, where the provision of the Services to you is, or becomes, unlawful); or (C) the partner with
                whom TfGF offered the Services to you has terminated its relationship with TfGF or ceased to offer the
                Services to you; or (D) TfGF is transitioning to no longer providing the Services to users in the
                country in which you are resident or from which you use the service; or (E) the provision of the
                Services to you by TfGF is, in TfGF’s opinion, no longer commercially viable.
            </P>
            <P>
                13.4 Nothing in this Section shall affect TfGF’s rights regarding provision of Services under Section
                4.of the Terms.
            </P>
            <P>
                13.5 When these Terms come to an end, all of the legal rights, obligations and liabilities that you and
                TfGF have benefited from, been subject to (or which have accrued over time whilst the Terms have been in
                force) or which are expressed to continue indefinitely, shall be unaffected by this cessation, and the
                provisions shall continue to apply to such rights, obligations and liabilities indefinitely.
            </P>
            <P title>14. Exclusion of Warranties </P>
            <P>
                14.1 The Services are provided "as is" and TfGF, its Subsidiaries and Affiliates, and its licensors give
                you no warranty with respect to them.
            </P>
            <P>
                14.2 In particular, TfGF, its Subsidiaries and Affiliates, and licensors do not represent or warrant to
                you that: (A) your use of the Services will meet your requirements, (B) your use of the Services will be
                uninterrupted, timely, secure or free from error, (C) any information obtained by you as a result of
                your use of the Services will be accurate or reliable, and (D) that defects in the operation or
                functionality of any Software provided to you as part of the Services will be corrected.
            </P>
            <P>
                14.3 No conditions, warranties or other terms (including any implied terms as to satisfactory quality,
                fitness for purpose or conformance with description) apply to the Services except to the extent that
                they are expressly set out in the Terms.
            </P>
            <P>
                14.4 Nothing in the Terms shall affect those statutory rights which you are always entitled to as a
                consumer and that you cannot contractually agree to alter or waive.
            </P>
            <P title>15. Limitation of Liability </P>
            <P>
                15.1 Nothing in these Terms shall exclude or limit TfGF’s liability for losses which may not be lawfully
                excluded or limited by applicable law.
            </P>
            <P>
                15.2 Subject to overall provision in paragraph 15.1 above, TfGF, its Subsidiaries and Affiliates, and
                its licensors shall not be liable to you for: (A) any indirect or consequential losses which may be
                incurred by you. This shall include any loss of profit (whether incurred directly or indirectly), any
                loss of goodwill or business reputation, or any loss of data suffered by you; (B) any loss or damage
                which may be incurred by you as a result of: (i) any reliance placed by you on the completeness,
                accuracy or existence of any advertising, or as a result of any relationship or transaction between you
                and any advertiser or sponsor whose advertising appears on the Services; (ii) any changes which TfGF may
                make to the Services, or for any permanent or temporary cessation in the provision of the Services (or
                any features within the Services); (iii) the deletion of, corruption of, or failure to store, any
                Content and other communications data maintained or transmitted by or through your use of the Services;
                (iv) your failure to provide TfGF with accurate account information; (v) your failure to keep your
                password or account details secure and confidential;
            </P>
            <P>
                15.3 The limitations on TfGF’s liability to you in paragraph 15.2 above shall apply whether or not TfGF
                has been advised of or should have been aware of the possibility of any such losses arising.
            </P>
            <P title>16. Copyright and trade mark policies </P>
            <P>
                16.1 It is TfGF’s policy to respond to notices of alleged copyright infringement that comply with
                applicable international intellectual property law and to terminating the accounts of repeat infringers.
                Details of TfGF’s policy can be found at http://www.form-timer/privacy.
            </P>
            <P title>17. Advertisements </P>
            <P>
                17.1 Some of the Services are supported by advertising revenue and may display advertisements and
                promotions. These advertisements may be targeted to the content of information stored on the Services,
                queries made through the Services or other information.
            </P>
            <P>
                17.2 The manner, mode and extent of advertising by TfGF on the Services are subject to change without
                specific notice to you.
            </P>
            <P>
                17.3 In consideration for TfGF granting you access to and use of the Services, you agree that TfGF may
                place such advertising on the Services.
            </P>
            <P title>18. Other content </P>
            <P>
                18.1 The Services may include hyperlinks to other web sites or content or resources. TfGF may have no
                control over any websites or resources which are provided by companies or persons other than TfGF.
            </P>
            <P>
                18.2 You acknowledge and agree that TfGF is not responsible for the availability of any such external
                sites or resources, and does not endorse any advertising, products or other materials on or available
                from such web sites or resources.
            </P>
            <P>
                18.3 You acknowledge and agree that TfGF is not liable for any loss or damage which may be incurred by
                you as a result of the availability of those external sites or resources, or as a result of any reliance
                placed by you on the completeness, accuracy or existence of any advertising, products or other materials
                on, or available from, such websites or resources.
            </P>
            <P title>19. Changes to the Terms </P>
            <P>
                19.1 TfGF may make changes to the Universal Terms or Additional Terms from time to time. When these
                changes are made, TfGF will make a new copy of the Universal Terms available at http://www.merge.email
                and any new Additional Terms will be made available to you from within, or through, the affected
                Services.
            </P>
            <P>
                19.2 You understand and agree that if you use the Services after the date on which the Universal Terms
                or Additional Terms have changed, TfGF will treat your use as acceptance of the updated Universal Terms
                or Additional Terms.
            </P>
            <P title>20. General legal terms </P>
            <P>
                20.1 Sometimes when you use the Services, you may (as a result of, or through your use of the Services)
                use a service or download a piece of software, or purchase goods, which are provided by another person
                or company. Your use of these other services, software or goods may be subject to separate terms between
                you and the company or person concerned. If so, the Terms do not affect your legal relationship with
                these other companies or individuals.
            </P>
            <P>
                20.2 The Terms constitute the whole legal agreement between you and TfGF and govern your use of the
                Services (but excluding any services which TfGF may provide to you under a separate written agreement),
                and completely replace any prior agreements between you and TfGF in relation to the Services.
            </P>
            <P>
                20.3 You agree that TfGF may provide you with notices, including those regarding changes to the Terms,
                by email, regular mail, or postings on the Services.
            </P>
            <P>
                20.4 You agree that if TfGF does not exercise or enforce any legal right or remedy which is contained in
                the Terms (or which TfGF has the benefit of under any applicable law), this will not be taken to be a
                formal waiver of TfGF’s rights and that those rights or remedies will still be available to TfGF.
            </P>
            <P>
                20.5 If any court of law, having the jurisdiction to decide on this matter, rules that any provision of
                these Terms is invalid, then that provision will be removed from the Terms without affecting the rest of
                the Terms. The remaining provisions of the Terms will continue to be valid and enforceable.
            </P>
            <P>
                20.6 You acknowledge and agree that each member of the group of companies of which TfGF is the parent
                shall be third party beneficiaries to the Terms and that such other companies shall be entitled to
                directly enforce, and rely upon, any provision of the Terms which confers a benefit on (or rights in
                favour of) them. Other than this, no other person or company shall be third party beneficiaries to the
                Terms.
            </P>
            <P>
                20.7 The Terms, and your relationship with TfGF under the Terms, shall be governed by Swiss law. You and
                TfGF agree to submit to the exclusive jurisdiction of the courts of Switzerland to resolve any legal
                matter arising from the Terms. Notwithstanding this, you agree that EmailgMerge shall still be allowed
                to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction. --
                April, 2021
            </P>
        </div>
    )
}
export default TermsOfService

const P: React.FunctionComponent<{ title?: boolean; children: React.ReactNode }> = ({ children, title }) => (
    <div className={`py-2 ${!!title && "font-weight-bold"}`}>{children}</div>
)
