import Typography from "@material-ui/core/Typography"

const Footer = () => {
    return (
        <footer style={{ padding: "2rem 0", marginTop: "4rem", width: "100%" }}>
            <div className="container">
                <div style={{ display: "flex", justifyContent: "space-around", marginBottom: "2rem" }}>
                    {/* Products Column */}
                    <div>
                        <Typography variant="h6" gutterBottom>
                            Products
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            <a href="https://form-timer.com" style={{ color: "inherit", display: "block", marginBottom: "0.5rem" }}>
                                Timer for Google Forms
                            </a>
                            <a href="https://merge.email" target="_blank" rel="noreferrer" style={{ color: "inherit", display: "block", marginBottom: "0.5rem" }}>
                                Mail Merge for Gmail
                            </a>
                            <a href="https://syncthemcalendars.com" target="_blank" rel="noreferrer" style={{ color: "inherit", display: "block", marginBottom: "0.5rem" }}>
                                SyncThem Calendars
                            </a>
                            <a href="https://mailtrack.email" target="_blank" rel="noreferrer" style={{ color: "inherit", display: "block", marginBottom: "0.5rem" }}>
                                Mail Tracker for Gmail
                            </a>
                        </Typography>
                    </div>

                    {/* Company Column */}
                    <div>
                        <Typography variant="h6" gutterBottom>
                            Company
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            <a href="https://qualtir.com" target="_blank" rel="noreferrer" style={{ color: "inherit", display: "block", marginBottom: "0.5rem" }}>
                                Qualtir
                            </a>
                            <a href="https://form-timer.com/privacy" target="_blank" rel="noreferrer" style={{ color: "inherit", display: "block", marginBottom: "0.5rem" }}>
                                Privacy Policy
                            </a>
                            <a href="https://form-timer.com/terms-of-service" target="_blank" rel="noreferrer" style={{ color: "inherit", display: "block", marginBottom: "0.5rem" }}>
                                Terms of Service
                            </a>
                        </Typography>
                    </div>
                </div>
                <Typography variant="body2" color="textSecondary" align="center">
                    © {new Date().getFullYear()} Timer for Google Forms. All rights reserved.
                </Typography>
            </div>
        </footer>
    )
}

export default Footer
