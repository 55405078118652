import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from 'react-router-dom'

import FormTimer from './components/FormTimer'
import Start from './components/Start'
import OutOfBounds from './components/OutOfBounds'
import PageNotFound from './components/PageNotFound'
import TermsOfService from './components/TermsOfService'
import Privacy from './components/Privacy'
import Help from './components/Help'
import Home from './components/Home'

import './App.css'

const App = () => {
  return (
    <Router>
      <Pages />
    </Router>
  )
}
export default App

const trimParams = (path: string) => {
  if (path.split('/').includes('start')) {
    return '/start'
  } else return path
}

const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

const Pages = () => {
  const history = useHistory()

  useEffect(() => {
    if (history) {

      if (GOOGLE_ANALYTICS_MEASUREMENT_ID) {
        console.log('initializing google analytics')
        ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID)
        ReactGA.pageview(trimParams(history.location.pathname))
        console.log('page=>', trimParams(history.location.pathname))
      } else {
        console.log('No google analytics id')
      }
      history.listen((location) => {
        // const loc = (window as any).location
        ReactGA.pageview(trimParams(location.pathname))
        console.log('page=>', trimParams(location.pathname))
      })
    } else {
      console.log('error: cannot use google analytics, history null')
    }
  }, [history])

  return (
    <div className="d-flex flex-column flex-grow-1">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route path="/home">
          <Home />
        </Route>

        <Route path="/start/:lookupId">
          <Start />
        </Route>

        <Route path="/app">
          <FormTimer />
        </Route>

        <Route path="/closed">
          <OutOfBounds />
        </Route>

        <Route path="/about">
          <div>Work in progress</div>
        </Route>

        <Route path="/privacy">
          <Privacy />
        </Route>

        <Route path="/terms-of-service">
          <TermsOfService />
        </Route>

        <Route path="/help">
          <Help />
        </Route>

        <Route path="/404">
          <PageNotFound />
        </Route>

        <Redirect from="*" to="/404" />
      </Switch>
    </div>
  )
}
