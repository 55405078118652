import firebase from 'firebase'

const { REACT_APP_FIREBASE_API_KEY, REACT_APP_FIREBASE_AUTH_DOMAIN, REACT_APP_FIREBASE_PROJECT_ID, REACT_APP_FIREBASE_STORAGE_BUCKET, REACT_APP_FIREBASE_MESSAGING_SENDER_ID, REACT_APP_FIREBASE_APP_ID, REACT_APP_FIREBASE_MEASUREMENT_ID } = process.env

if (!REACT_APP_FIREBASE_API_KEY || !REACT_APP_FIREBASE_AUTH_DOMAIN || !REACT_APP_FIREBASE_PROJECT_ID || !REACT_APP_FIREBASE_STORAGE_BUCKET || !REACT_APP_FIREBASE_MESSAGING_SENDER_ID || !REACT_APP_FIREBASE_APP_ID) {
  throw new Error("Missing Firebase environment variables")
}

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  ...(REACT_APP_FIREBASE_MEASUREMENT_ID && { measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID }),
}
firebase.initializeApp(firebaseConfig)

export const firestore = firebase.firestore()
