import React from "react"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

const Help = () => {
    return (
        <div className="container">
            <Typography variant="h5" className="pt-4">
                Timer for Google Forms
            </Typography>
            <Typography variant="h4" className="pt-2 pb-4">
                Support
            </Typography>
            <div style={{ borderTop: "1px solid grey" }} className="d-flex flex-column align-items-center py-5">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        const url = "mailto:F?subject=FormTimer%20contact"
                        return window.open(url, "_blank")
                    }}
                >
                    Contact us
                </Button>
                <Typography variant="caption" className="pt-2">
                    We typically reply within a day
                </Typography>
            </div>

            <div style={{ borderTop: "1px solid grey" }} className="d-flex flex-column align-items-start py-3">
                <Typography variant="h4" className="py-2">
                    FAQ
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body1" className="py-2 font-weight-bold">
                            The submission time does not appear in the responses spreadsheet
                        </Typography>
                        <Typography variant="body2">
                            If this issue happens, send us immediately an e-mail and we will correct it for you.
                        </Typography>
                        <Typography variant="body2">
                            It might also be that the respondent modified the unique identifier field, in which case we
                            can provide you with the submission time
                        </Typography>
                    </li>

                    <li>
                        <Typography variant="body2" className="py-2 font-weight-bold">
                            We complete this FAQ with common issues each time users contact us with an issue. Do not
                            hesitate to do so.
                        </Typography>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default Help
